import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
import PrevFooter from './PrevFooter';
import asoc from "../../assets/logosUe/asoc.svg";
import part from "../../assets/logosUe/part.svg";
import tic from "../../assets/logosUe/ComunicacionTICNegociosTransformacionDigital.png";


const Footer = (props) => {


  return (


    <div className="container-fluid footer">
      <div className="row">
        <div className="col-12 col-lg-10">
          <PrevFooter/>
          <div className="row justify-content-between mt-2">
            <div className="col-12 col-md-auto mt-3 mt-md-0">
              <div className="">
                <ul className="nav justify-content-start">
                  <div className="">
                    <span className="nav-link">2020 – Hi-Services ® SenyProjects |</span>
                  </div>
                  <li className="nav-item">
                    <a className="nav-link" href="/policy">Aviso Legal |</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/policy">Política de privacidad</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-auto mt-3 mt-md-0">
              <div className="nav justify-content-center">
                <a className="d-flex align-items-center mx-3" href="https://www.facebook.com/hihealthinnovation/">
                  <FaFacebookF color="white" size="20px"/>
                </a>
                <a className="d-flex align-items-center mx-3" href="https://www.instagram.com/hi_healthinnovation/">
                  <FaInstagram color="white" size="23px"/>
                </a>
                <a className="d-flex align-items-center mx-3"
                   href="https://www.youtube.com/channel/UCCbAI0umM4S4vRSkLbYi1ng">
                  <FiYoutube color="white" size="26px"/>
                </a>
                <a className="d-flex align-items-center mx-3" href="https://www.linkedin.com/company/hiservices/">
                  <FaLinkedin color="white" size="26px"/>
                </a>
                <a className="d-flex align-items-center mx-3" href="https://twitter.com/HiservicesHeal1">
                  <FaTwitter color="white" size="23px"/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-2 bg-light">
          <div className="row h-100 justify-content-center align-items-center flex-lg-column">
            <img src={asoc} className='m-3' height="75px" alt='asoc'/>
            <img src={part} className='m-3' height="75px" alt='part'/>
          </div>
        </div>
        <div className="col-12">
          <div className="row justify-content-center align-items-center flex-lg-column p-2">
            <img src={tic} className='m-1' style={{maxWidth: '90%', width: 400}} alt='tic'/>
          </div>
        </div>
      </div>
    </div>

  );
}


export default Footer;
