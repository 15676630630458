import React, { useState, useEffect } from "react";
import Config from "./Config";
import { Row } from "reactstrap";
import loading from "./../assets/loading.svg";

const Partners = () => {
  const [institutions, setInstitutions] = useState(undefined);
  const [associations, setAssociations] = useState(undefined);
  const [companies, setCompanies] = useState(undefined);
  useEffect(() => {
    fetch(`${Config.API_SERVER}/institutions`)
      .then((response) => response.json())
      .then((data) => {
        setInstitutions(data);
      })
      .catch(() => {
        setInstitutions(null);
      });
    fetch(`${Config.API_SERVER}/associations`)
      .then((response) => response.json())
      .then((data) => {
        setAssociations(data);
      })
      .catch(() => {
        setAssociations(null);
      });
    fetch(`${Config.API_SERVER}/companies`)
      .then((response) => response.json())
      .then((data) => {
        setCompanies(data);
      })
      .catch(() => {
        setCompanies(null);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="container-fluid mainBanner">
        <div
          className="row position-relative align-items-center"
          style={{ minHeight: "50vh" }}
        >
          <div
            className="col position-absolute h-100 w-100"
            style={{ backgroundColor: "#444647", opacity: 0.8, zIndex: 100 }}
          >

          </div>
          <div className="container-fluid h-100 px-2" style={{ zIndex: 101 }}>
            <div className="row h-100 align-items-center px-2 px-lg-5">
              <div>
                <div className="col-12 col-md-6">
                  {/*<h1 className="title py-lg-3">¿Qué es Hi-Services?</h1>*/}

                  <h1 align="center" className="pb-3">
                    Juntos, en la salud… y en la salud.
                  </h1>
                  <div>
                    <p>
                      Nuestro compromiso es con el bienestar de las personas. Es
                      por eso que contamos entre nuestros socios con numerosas
                      instituciones, empresas y asociaciones que velan, como
                      nosotros, por la salud de sus ciudadanos, clientes y
                      asociados en todo el mundo.
                    </p>
                    <p>
                      Instituciones como la Asociación Española contra el Cáncer
                      (AECC), la Asociación de Educación Ambiental al Consumidor
                      (ADEAC) conjuntamente con Bandera Azul, la Sociedad
                      Española de Medicina Intensiva Crítica y Unidades
                      Coronarias (Semicyuc), la Fundación Cirugía y Corazón, la
                      Asociación Nacional de Personas con Epilepsia (ANPE), el
                      112, el Colegio Oficial de Médicos de las Islas Baleares
                      (COMIB), el Colegio Oficial de Farmacéuticos de las Islas
                      Baleares (COFIB)… avalan y validan nuestro proyecto
                      incluyéndolo como buena práctica en toda la misión y
                      visión de estas a través de convenios a nivel nacional.
                    </p>
                    <p>
                      Con esta colaboración se consigue mayor presencia,
                      visibilidad y especialización para todas las acciones de
                      prevención y actuación que, juntos, llevamos a cabo.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row justify-content-center mt-4">
          <h2 className="">Instituciones</h2>
        </div>
        <div className="row justify-content-center align-items-center my-4">
          {institutions === undefined && (
            <img src={loading} width="60px" height="60px" alt="" style={{filter: "grayscale(100%)"}}/>
          )}
          {institutions &&
          institutions.map((institution, key) => {
            return (
              <img
                key={key}
                className="m-3"
                style={{ height: "150px", filter: "grayscale(100%)" }}
                src={Config.API_SERVER + institution.image.url}
                alt=""
              />
            );
          })}
        </div>
        <div className="row justify-content-center mt-4">
          <h2 className="">Asociaciones</h2>
        </div>
        <Row className="my-4 justify-content-center">
          {associations === undefined && (
            <img src={loading} width="60px" height="60px" alt=""/>
          )}
          {associations &&
          associations.map((association, key) => {
            return (
              <img
                key={key}
                className="m-3"
                style={{ height: "150px", filter: "grayscale(100%)" }}
                src={Config.API_SERVER + association.image.url}
                alt=""
              />
            );
          })}
        </Row>
        <div className="row justify-content-center mt-4">
          <h2 className="">Empresas</h2>
        </div>
        <div className="row justify-content-center my-4">
          {companies === undefined && (
            <img src={loading} width="60px" height="60px" alt=""/>
          )}
          {companies &&
          companies.map((company, key) => {
            return (
              <img
                key={key}
                className="m-3"
                style={{ height: "150px", filter: "grayscale(100%)" }}
                src={Config.API_SERVER + company.image.url}
                alt=""
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Partners;
