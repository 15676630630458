/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Button } from "reactstrap";
import solmaforo from "../assets/Producto-Solmáforo.jpg";
import desfibrilador from "../assets/Producto-desfibrilador.jpg";
import lactancia from "../assets/Foto-producto-zona-lactancia-V2.jpg";
import video from "../assets/Demo-HI-SERVICE.mp4";
import { Link, NavLink } from "react-router-dom";
import demoImg from "./../assets/demo.png";

const Products = (props) => {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row productBanner position-relative">
          <div
            className="col position-absolute h-100 w-100"
            style={{ backgroundColor: "#444647", opacity: 0.8, zIndex: 100 }}
          ></div>
          <div className="container-fluid h-100 px-2" style={{ zIndex: 101 }}>
            <div className="row h-100 align-items-center px-2 px-lg-5">
              <div className="col-12 col-md-6">
                {/*<h1 className="title py-lg-3">¿Qué es Hi-Services?</h1>*/}
                <h1 className="pb-3">
                  El Hi-Point, el punto de encuentro de la prevención y la
                  actuación.
                </h1>
                <p>
                  El Hi-Point es una estructura modular adaptable a cualquier
                  entorno, público o privado, cuyo principal objetivo es
                  concienciar a las personas sobre las pautas de prevención y
                  actuación saludable específicas a cada entorno.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mx-0 px-0 col-lg-6 bg-white py-5">
            <div className="row justify-content-center">
              <video
                controls={false}
                controlsList="nodownload"
                id="myVideo"
                className="headerVideo"
                src={video}
                muted
                autoPlay
                style={{ maxWidth: "300px" }}
                poster={demoImg}
              >
                <source
                  src={video}
                  className="headerVideo"
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
              </video>
            </div>
          </div>
          <div className="col-12 col-lg-6 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">¿Qué elementos integran un Hi-Point?</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
                PANTALLA DIGITAL, TÁCTIL E INTERACTIVA
                <br />
                Informa sobre la prevención de problemas generales de la salud y
                procesos de protocolo de actuación en caso de emergencia.
                <br />
                Dispone además del único software para la detección del fototipo
                de piel de cada usuario y genera un informe personalizado
                identificando los factores de riesgo para su salud:
                recomendación del factor mínimo de protección solar a utilizar,
                tiempos máximos de exposición solar, radiación ultravioleta y
                consejos básicos de prevención.
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="justify-content-center m-auto ">
                <div className="d-flex   align-middle py-3">
                  <Button>
                    <Link to="/contact" className="white">
                      CONTÁCTANOS
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">SOLMÁFORO</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
                Mide los niveles de radiación ultravioleta, alertando a la
                población por medio de un código basado en cinco colores (verde,
                amarillo, naranja, rojo y violeta) determinados por la
                Organización Mundial de la Salud (OMS). Los índices se expresan
                con valores que van del 1 al 11.
              </div>
            </div>
          </div>
          <div className="col-12 mx-0 px-0 col-lg-6 bg-white order-0 order-lg-1">
            <img
              style={{ width: "100%" }}
              src={solmaforo}
              className="m-auto"
              alt="some"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mx-0 px-0 col-lg-6 bg-white">
            <img
              style={{ width: "100%" }}
              src={desfibrilador}
              className="m-auto"
              alt="some"
            />
          </div>
          <div className="col-12 col-lg-6 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">DESA Y BOTÓN 112</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
                DESFIBRILADOR EXTERNO SEMIAUTOMÁTICO (DESA).
                <p>
                  Aparato portátil que diagnostica y trata la parada
                  cardiorrespiratoria, restableciendo el ritmo cardíaco habitual
                  a través de impulsos de corriente continua cuando el corazón
                  está fibrilando.
                </p>
                BOTON DE EMERGENCIAS 112
                <p>
                  Dispositivo de llamada automática e interfono de comunicación
                  conectado al servicio y protocolo de actuación de emergencias
                  del 112 de les Illes Balears.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6 order-1 order-lg-0 py-4">
            <div className="row pt-5 pb-3 px-lg-5">
              <h1 className="">ZONA DE SOMBRA</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
                ZONA DE LACTANCIA, ZONA DE DESCANSO O ZONA DE SOMBRA
                <p>
                  {" "}
                </p>
                ZONA DE CARGA
                <p>
                  De dispositivos móviles y zona de carga para sillas de ruedas
                  eléctricas.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mx-0 px-0 col-lg-6 bg-white order-0 order-lg-1">
            <img
              style={{ width: "100%" }}
              src={lactancia}
              className="m-auto"
              alt="some"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid bg-white" style={{ color: "#5B7E96" }}>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 order-1 order-lg-0">
            <div className="row justify-content-center pt-5 pb-3 px-lg-5">
              <h1 className="">¿POR QUÉ ADQUIRIRLO?</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col px-2 m-0 text-justify">
                <div>
                  ✓ Es un producto de Salud Pública gratuita para todos los
                  ciudadanos.
                </div>
                <div>
                  ✓ Es un producto innovador y de diseño vanguardista adaptable
                  a cualquier entorno. Además, es sostenible, versátil y
                  duradero porque está tratado para resistir la acción de la
                  lluvia, el sol, el viento, el salitre, los insectos y los
                  hongos.
                </div>
                <div>
                  ✓ Dispone del único software capaz de detectar el fototipo de
                  piel de una persona y cruzarlo con los datos obtenidos de
                  radiación UV para emitir informes personalizados para cada
                  usuario.
                </div>
                <div>
                  ✓ Toda la información está avalada y certificada por las
                  principales instituciones médicas.
                </div>
                <NavLink to="/property" className="text-white">
                  <div>
                    ✓ Es un producto único con patente de diseño industrial
                    propiedad de la empresa mallorquina Innovació Turística
                    Balears, S.L (Hi services).
                  </div>
                </NavLink>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="justify-content-center m-auto ">
                <div className="d-flex   align-middle py-3">
                  <Button style={{ backgroundColor: "#5B7E96" }}>
                    <Link to="/contact" className="white">
                      CONTÁCTANOS
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Products;
