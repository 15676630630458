import React from 'react';
import europ from '../../assets/logosUe/europaFooter.png'
import feder from '../../assets/logosUe/feder.png'
import illes from '../../assets/logosUe/illes.png'
import logo from "../../assets/icons/BRAND.png"


const PrevFooter = () => {


  return (
    <div className="">
      <div className="row justify-content-center justify-content-md-start align-items-center mt-3">
        <img src={logo} alt={"logo"} height="50px" className="m-3"/>
        <div className="w-100 d-md-none mt-3"/>
        <img src={illes} height="50px" className='ml-md-auto' alt='illes Balears'/>
        <div className="w-100 d-md-none"/>
        <img src={europ} className='mx-md-5' height="50px" alt='europ'/>
        <div className="w-100 d-md-none"/>
        <img src={feder} className='mx-md-5' height="50px" alt='feder'/>
        <div className="d-flex w-100 justify-content-end py-3 mx-2 mx-sm-5 text-center" style={{opacity: 0.8, fontSize: "12px"}}>
          Aquest projecte està cofinançat en un 50 % amb càrrec al Programa Operatiu FEDER 2014-2020 de les Illes Balears
        </div>
      </div>
    </div>

  );
}


export default PrevFooter;
