import React, { useEffect } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom'
import Home from './components/Home'
import NavBar from './components/NavBar'
import AboutUs from './components/AboutUs'
import Contact from './components/Contact'
import PrivacyPolicy from './components/PrivacyPolicy'
import Products from './components/Products'
import Blog from './components/blog/Blog'
import Article from './components/blog/Article'
import Footer from './components/footer/Footer'
import Partners from './components/Partners';
import MapSection from './components/map/MapSection';
import Cookies from './components/Cookies'
import Property from "./components/Property";
import { useLocation } from "react-router";
import Swal from 'sweetalert2';


function App() {
  let location = useLocation();
  React.useEffect(() => {
    Swal.close();
  }, [location]);
  return (
    <div className="App">
      <ScrollToTop/>
      <NavBar/>
      <Cookies/>
      <Switch>
        <Route exath path="/contact" component={Contact}/>
        <Route exath path="/products" component={Products}/>
        <Route exath path="/blog" component={Blog}/>
        <Route exath path="/partners" component={Partners}/>
        <Route exath path="/policy" component={PrivacyPolicy}/>
        <Route exath path="/aboutUs" component={AboutUs}/>
        <Route exath path="/article/:url" component={Article}/>
        <Route exath path="/map" component={MapSection}/>
        <Route exath path="/property" component={Property}/>
        <Route exath path="/" component={Home}/>
      </Switch>
      <Footer/>
    </div>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    // modal.setContent(null);
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default App;
