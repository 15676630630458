import React from 'react';


const AboutUs = () => {

  return (
    <div className="container-fluid aboutBanner">
      <div className="row align-items-center position-relative" style={{ minHeight: "85vh" }}>
        <div className="col position-absolute h-100 w-100"
             style={{ backgroundColor: "#444647", opacity: 0.8, zIndex: 100 }}>
        </div>
        <div className="container-fluid h-100 px-2" style={{ zIndex: 101 }}>
          <div className="row h-100 align-items-center px-2 px-lg-5">
            <div className="col-12 col-md-6">
              {/*<h1 className="title py-lg-3">¿Qué es Hi-Services?</h1>*/}
              <h1 className="pb-3">Salud, Innovación y diseño para todos</h1>
              <p>Health Innovation Services es un proyecto empresarial que
                integra innovación y tecnología al servicio de la salud de las personas a través del diseño y la
                producción de herramientas de prevención y actuación portátiles, móviles y virtuales</p>
              <p>
                Porque en Health Innovation Services sabemos
                que, para integrar rutinas de cuidado saludable en la vida de las personas, hay que estar presente en su
                día a día y en los espacios que habitan y transitan. Por eso, desarrollamos productos para espacios
                públicos y privados. Para instituciones, asociaciones y empresas que saben que la tecnología permite
                respuestas individualizadas a necesidades de prevención y actuación específicas.
                <br/>
                Nuestra Misión es la de poner a disposición de
                todos los ciudadanos, todas las herramientas innovadoras de concienciación, prevención y actuación, de
                forma gratuita y fácil a pie de calle. Y hacerlo de forma conjunta con las principales Instituciones,
                Asociaciones y Administraciones Públicas disponiendo de una información veraz, actualizada y validada
                por éstas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AboutUs;
