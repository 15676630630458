import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
} from 'reactstrap';
import {  Button } from 'reactstrap';


const items = [
  {
    text: 'Fruto de la tecnología más humana…',
    subtitle: 'Nace como un elemento de prevención y socorro, el punto informativo de referencia para la salud de las personas.',
    btnTitle: 'CONTÁCTANOS'
  },
  {
    text: '',
    subtitle: 'Único software diseñado para la detección del fototipo de piel de cada usuario, recomendación del factor mínimo de protección solar a utilizar e informe personalizado identificando los factores de riesgo para su salud.',
    btnTitle: 'SOBRE NOSOTROS'
  },
];

const Banner = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  // const goToIndex = (newIndex) => {
  //   if (animating) return;
  //   setActiveIndex(newIndex);
  // }

  const slides = items.map((item, key) => {
    return (
      <CarouselItem
        key={key}
      className=""
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <div className="banner m-lg-5 px-3">
            <h1 className="">{item.text}</h1>
            <h3 className="">{item.subtitle}</h3>
            <Button className="btnBanner mt-3">{item.btnTitle}</Button>
        </div>
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      {/* <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} /> */}
      {slides}
      {/* <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} /> */}
    </Carousel>
  );
}

export default Banner;

