import React, { useState } from 'react';
import { Col, Row, Label, Button, Form, FormGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { sendEmail } from "./sendEmail";

const getHtmlForEmail = (info) => {
  return `<div><p><b>Nombre:</b> ${info.name}</p><p><b>Email:</b> ${info.email}</p><p><b>Cargo:</b> ${info.cargo || "..."}</p><p><b>Teléfono:</b> ${info.phone}</p><p><b>Mensaje:</b></p><p style="border: 1px solid darkgray; min-height: 50px; padding: 7px 7px 7px 7px">${info.text}</p></div>`
};


const ContactForm = () => {
  const [check, setCheck] = useState(false)
  const [state, setState] = useState({})

  const change = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const submit = (event) => {
    event.preventDefault();

    sendEmail({ title: "HI-SERVICE - Contacto", html: getHtmlForEmail(state) }).then(() => {
      alert("Mensaje enviado correctamente, pronto nos pondremso en contacto con usted")
    }).catch(() => {
      alert("¡Ups! Ha ocurrido un problema al enviar el mensaje, revisa que todo esté correctamente completado")
    })
  };

  return (
    <div>
      <Form onSubmit={submit}>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Input className="input" required type="text" name="name" value={state.name} id="exampleEmail"
                     placeholder="Nombre"
                     onChange={change}/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Input className="input" required type="number" name="phone" value={state.phone} id="examplePassword"
                     placeholder="Teléfono" onChange={change}/>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Input className="input" type="text" name="cargo" value={state.cargo} id="exampleEmail"
                     placeholder="Cargo" onChange={change}/>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Input className="input" required type="email" name="email" value={state.email} id="exampleEmail"
                     placeholder="Email" onChange={change}/>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <Input className="input" required type="textarea" value={state.text} name="text" id="exampleText"
                   placeholder="¿Cómo podemos ayudarte?" onChange={change}/>
          </Col>
        </Row>
        <FormGroup className="my-3 ml-5">
          <Label>
            <Input type="checkbox" onChange={e => setCheck(e.target.checked)}/>
            Acepto los términos establecidos en <Link to="/policy">Aviso legal.</Link>
          </Label>
        </FormGroup>
        <div className="w-100 justify-content-center d-flex">
          <Button type={"submit"} disabled={!check} className="blueColor m-3 border">Enviar</Button>
        </div>
      </Form>
    </div>

  );
}


export default ContactForm;













