import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import logo from "../assets/icons/BRAND.png"
import { Link, NavLink } from 'react-router-dom';
import HamburgerMenu from 'react-hamburger-menu';

const NavBar = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const closeNavbar = () => setCollapsed(true);

  return (
    <div className="container-fluid shadow position-sticky"
         style={{ top: 0, backgroundColor: "#5B7E96", zIndex: 3000 }}>
      <div className="row justify-content-between align-items-center py-2 px-3">
        <div className="col col-auto">
          <NavLink to="/">
            <img src={logo} height="75px" className="d-none d-xl-block" alt={"logo"}/>
            <img src={logo} height="60px" className="d-xl-none" alt={"logo"}/>
          </NavLink>
        </div>
        <div className="col col-auto" style={{ cursor: "pointer" }}>
          <HamburgerMenu
            isOpen={!collapsed}
            className="d-xl-none"
            menuClicked={toggleNavbar}
            width={27}
            height={16}
            strokeWidth={2}
            rotate={0}
            color='#ffffff'
            borderRadius={2}
            animationDuration={0.5}
          />
        </div>
        <div className="col col-auto d-none d-xl-block">
          <Link to="/aboutUs" className="blueColor navItems">Empresa</Link>
          <Link to="/products" className="blueColor navItems">Producto</Link>
          <Link to="/partners" className="blueColor navItems">Partners</Link>
          <Link to="/map" className="blueColor navItems">Ubicaciones</Link>
          <Link to="/blog" className="blueColor navItems">Noticias</Link>
          <Link to="/contact" className="blueColor navItems">Contacto</Link>
        </div>
      </div>
      <div className="row">
        <Collapse isOpen={!collapsed} className="w-100">
          <div className="col d-xl-none">
            <div className="row py-4 justify-content-center align-items-center">
              <Link to="/aboutUs" className="blueColor navItems my-2" onClick={closeNavbar}>Empresa</Link>
              <Link to="/products" className="blueColor navItems my-2" onClick={closeNavbar}>Producto</Link>
              <Link to="/partners" className="blueColor navItems my-2" onClick={closeNavbar}>Partners</Link>
              <Link to="/map" className="blueColor navItems my-2" onClick={closeNavbar}>Ubicaciones</Link>
              <Link to="/blog" className="blueColor navItems my-2" onClick={closeNavbar}>Noticias</Link>
              <Link to="/contact" className="blueColor navItems my-2" onClick={closeNavbar}>Contacto</Link>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}

export default NavBar;
