import React, { useEffect, useState } from 'react';
import ReactMarkdown from "react-markdown"
import Config from '../Config';
import moment from "moment";


const Article = (props) => {
  const [article, setArticle] = useState(null);
  const urlArticle = props.match.params.url;

  useEffect(() => {

    fetch(`${Config.API_SERVER}/articles?url=${urlArticle}`)
      .then(response => response.json())
      .then(data => {
        setArticle(data[0]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!article) return null

  const bg = {
    height: "60vh",
    backgroundImage: "url(" + Config.API_SERVER + article.image.url + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover"
  };

  return (
    <React.Fragment>

      <div className="container-fluid" style={bg}>
      </div>
      <div className="container" style={{ minHeight: "40vh" }}>
        <div className="row py-5">
          <h1 className="w-100 mb-0 pb-0">{article.name}</h1>
          <div className="mr-2">Realizado por: {article.author}</div>
        </div>
        <div className="row">
          <div className="col-12 m-0 p-0">
            <ReactMarkdown source={article.content} transformImageUri={(path) => Config.API_SERVER + path}/>
          </div>
        </div>
        <div className="row py-5">
          <div>{moment(article.date).format("DD/MM/YYYY HH:mm")}</div>
        </div>
      </div>
    </React.Fragment>
  );
}


export default Article;
