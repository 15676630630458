import React, { useEffect, useState } from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import * as L from 'leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import 'leaflet/dist/leaflet.css';
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import mark from "./../assets/markers/mark.svg"
import Config from "./Config";


export default () => {
  const [center, setCenter] = useState([39.547856, 2.805966])
  const [points, setPoints] = useState([])

  useEffect(() => {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    fetch(`${Config.API_SERVER}/config`)
      .then(response => response.json())
      .then(data => {
        setCenter([data.centerMapLatitude, data.centerMapLongitude]);
      })
      .catch(() => console.log("Error Map"))
    fetch(`${Config.API_SERVER}/points`)
      .then(response => response.json())
      .then(data => {
        setPoints(data);
      })
      .catch(() => console.log("Error Map"))

  }, [])

  const icon = L.icon({
    iconUrl: mark,
    iconSize: [100, 100],
  });

  return (
    <Map center={center} zoom={8} className="w-100 min-vh-100 bg-transparent" gestureHandling={true}>
      <TileLayer attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {points.map((point, key) => {
        return (
          <Marker key={key} position={[parseFloat(point.latitude), parseFloat(point.longitude)]} icon={icon}


              /* onclick={() => {
                if (point.image) {
                  Swal.fire({
                    title: point.location,
                    position: 'bottom-start',
                    imageUrl: "https://backend.hi-services.es:1341" + point.image.url,
                    imageWidth: "100%",
                    imageHeight: "100%",
                    imageAlt: "Custom image",
                    showConfirmButton: false,
                  })
                }
              }
              } */>
            {/*  <Tooltip direction='top' opacity={0.9} sticky>
              {point.location}
            </Tooltip> */}
            <Popup className="bg-light p-3">
              <div className="card" style={{ width: "18rem" }}>
                {point.image && <img alt=" " className="card-img-top" src={"https://backend.hi-services.es:1341" + point.image.url}/>}
                <div className="card-body">
                  <h4>{point.location}</h4>
                  <a href={"https://www.google.es/maps/search/" + point.latitude + "," + point.longitude} className="card-link">Cómo llegar</a>
                </div>
              </div>
           </Popup>
          </Marker>

        )
      })}
    </Map>
  );
}
