import React from 'react';
import ContactForm from "./ContactForm";

const Contact = (props) => {
  const texts = [
    "C/d’en Miquel dels Sants Oliver, 23",
    "07200, Felanitx",
    "Illes Balears (Spain)",
    "Tel. +34 637 82 77 59"
  ]

  return (
    <div className="container-fluid" style={{ minHeight: "70vh" }}>
      <div className="row h-100 py-5 justify-content-center">
        <div className="col-12 col-lg-5">
          <div className="row justify-content-center text-center pt-5 pb-3 px-lg-5">
            <h1>¿Podemos ayudarte? </h1>
          </div>
          <div className="row justify-content-center text-center px-lg-5">
            <div className="col text-white px-2 m-0">
              {texts.map((text, key) => <div key={key}>
                {text}
              </div>)}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5 mt-5 mt-lg-0">
          <ContactForm/>
        </div>
      </div>
    </div>
  )
}

export default Contact;
