import React, { useState } from 'react';
import { Button, Modal} from 'reactstrap';

const ModalExample = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(!localStorage.getItem("Cookies-hi-services"));

  const toggle = () => {
    localStorage.setItem("Cookies-hi-services", "true");
    setModal(!modal)
  };

  return (
    <div>
      <Modal isOpen={modal} className={className}>
        <div className="container rounded shadow" style={{ width: "90vw", backgroundColor: "#fff", color: '#5b7e96' }}>
          <div className="row py-4 px-4 px-md-5" style={{ fontSize: "20px" }}>
            TU PRIVACIDAD ES IMPORTANTE PARA NOSOTROS
          </div>
          <div className="row px-4 px-md-5">
            Esta web utiliza cookies para personalizar contenido y publicidad, proporcionar funcionalidades a las redes
            sociales, o analizar nuestro tráfico. Si sigues navegando consientes el uso de esta tecnología en nuestra
            web. Para más información visita nuestro apartado
            Cookies
          </div>
          <div className="row px-4 px-md-5">
            <Button color="primary my-3 mx-auto" onClick={toggle}>Aceptar</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalExample;

// import React, {  useState } from "react";
// import { NavLink} from "react-router-dom";

// const NAME_VAR_STORAGE = "cookies-accept";


// const Cookies = ( changeModal) => {
//     const [accepted, setAccepted] = useState(!!localStorage.getItem(NAME_VAR_STORAGE));
//     const accept = (event) => {
//         localStorage.setItem(NAME_VAR_STORAGE, "true");
//         setAccepted(true);
//         // changeModal();
//       };

//   return (
// <div className="container rounded shadow" style={{ width: "90vw", backgroundColor: "#fff", color: 'pink' }}>
//   <div className="row py-4 px-4 px-md-5" style={{ fontSize: "20px" }}>
//   TU PRIVACIDAD ES IMPORTANTE PARA NOSOTROS
//   </div>
//   <div className="row px-4 px-md-5">
//   Esta web utiliza cookies para personalizar contenido y publicidad, proporcionar funcionalidades a las redes sociales, o analizar nuestro tráfico. Si sigues navegando consientes el uso de esta tecnología en nuestra web. Para más información visita nuestro apartado
//     <NavLink to="/cookies" style={{ textDecoration: "underline" }}>
//       Cookies
//     </NavLink>
//   </div>
//   <div className="row justify-content-end py-4 px-4 px-md-5">
//     <button onClick={accept} style={{ maxWidth: "200px" }}
//             className="btn btn-block btn-product border-secondary primary py-3">Acceptar</button>
//   </div>
// </div>
//   )
// };
// export default Cookies;
