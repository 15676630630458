import React from "react";

export default () => {
  return (
    <div className="container pt-5 my-5">
      <h2 className="row flex-center title mb-5">Privacidad</h2>
      <p>
        Innovació Turistica Balears, S.L con CIF B57894941 (HI-SERVICES) en
        cumplimiento del Reglamento Europeo 2016/679 de 27 de abril de 2016,
        relativo a la protección de las personas físicas en lo que respecta al
        tratamiento de datos personales y a la libre circulación de datos, desea
        poner en conocimiento de sus clientes la política llevada a cabo
        respecto al tratamiento y protección de los datos de carácter personal
        de aquellas personas que voluntariamente utilizan los formularios para
        contratar con FIBWI, cualquiera de los servicios ofrecidos, que
        impliquen la comunicación de sus datos personales a FIBWI.
      </p>
      <h3 className="row flex-center text-bg my-5">
        <strong>Identificación del responsable del tratamiento.</strong>
      </h3>

      <table>
        <tr>
          <th>Responsable</th>
          <th>Innovació Turistica Balears, S.L (en adelante, HI-SERVICES)</th>
        </tr>
        <tr>
          <td>Finalidad</td>
          <td>
            Gestión del acceso completo a servicios, a su actualización y
            comunicaciones de todo tipo
          </td>
        </tr>
        <tr>
          <td>Legitimación</td>
          <td>Consentimiento EXPRESO de la persona interesada</td>
        </tr>
        <tr>
          <td>Destinatarios</td>
          <td>
            No existe cesión de datos a terceros, salvo las obligaciones legales
            a que está sujeta FIBWI
          </td>
        </tr>
        <tr>
          <td>Derechos</td>
          <td>
            Acceso, rectificación, supresión, oposición, limitación del
            tratamiento y portabilidad
          </td>
        </tr>
      </table>

      <h3 className="row flex-center text-bg my-5">
        <strong>Actualización de las políticas.</strong>
      </h3>

      <p>
        HI-SERVICES modificará, sin previo aviso, la presente política de
        privacidad siempre que sea necesario para adecuar la misma a cualquier
        cambio legislativo, reglamentario, jurisprudencial, administrativo o con
        la finalidad de adaptar dicha política a las instrucciones dictadas por
        el Parlamento Español, el Parlamento Europeo y el Consejo de la Unión
        Europea u objeto legítimo de HI-SERVICES. Cualquier modificación de esta
        política no obstante lo anterior, será publicada y advertida por
        HI-SERVICES. Por todo lo anterior, HI-SERVICES recomienda a los clientes
        la lectura periódica de estas políticas con el fin de poder conocer los
        cambios que en las mismas se efectúen.
      </p>

      <h3 className="row flex-center text-bg my-5">
        <strong>Finalidad del tratamiento.</strong>
      </h3>

      <p>
        La comunicación de datos personales por los clientes a HI-SERVICES a
        través de sus formularios, únicamente puede entenderse que tendrá Iugar
        cuando éstos, voluntariamente utilicen el servicio de formulario de
        suscripción para ponerse en contacto con HI-SERVICES, dado que en estos
        casos el tratamiento de los datos es inevitable e implícito al sistema
        de consulta y/o contratación. Para estos casos y los descritos en el
        siguiente apartado, la entidad, informa a sus clientes, que el
        tratamiento de los datos se realiza con las siguientes finalidades:
      </p>

      <ol>
        <li>
          En cumplimiento de un contrato, para llevar a cabo todas las gestiones
          administrativas, contables, fiscales y logísticas vinculadas con la
          contratación y prestación de servicios y productos de HI-SERVICES, en
          relación a la comercialización de los productos HI-SERVICES, a través
          de comercio electrónico, gestionar la agenda de contactos de nuestra
          empresa y atender a sus peticiones de consulta vía electrónica.
        </li>

        <li>
          Otorgamiento de su consentimiento EXPRESO (puede retirarlo en
          cualquier momento, sin que afecte a la licitud del tratamiento. Lea el
          capítulo 10.- Ejercicio de los derechos ARCO), para:
          <ol>
            <li>
              Aplicación personalizada de campañas promocionales, suscripción a
              newletters, comunicaciones comerciales, invitaciones a eventos y/o
              encuestas de distinta índole.
            </li>
            <li>
              Uso de forma anónima, sin ninguna característica que le pueda
              identificar, con la finalidad de realizar análisis y estudio
              estadísticos propios o de terceros.
            </li>
          </ol>
        </li>

        <li>
          Por interés legítimo de HI-SERVICES, para:
          <ol>
            <li>
              Mejorar sus expectativas e incrementar su grado de satisfacción
              como cliente al desarrollar y mejorar la calidad de productos y
              servicios propios o de terceros, así como realizar estadísticas,
              encuestas o estudios de mercado que puedan resultar de interés.
            </li>
            <li>
              Comunicaciones de cortesía, tipo felicitación por aniversario,
              desearte felices fiestas o simplemente un buen día.
            </li>
          </ol>
        </li>
      </ol>

      <h3 className="row flex-center text-bg my-5">
        <strong>Lugar de tratamiento.</strong>
      </h3>
      <p>
        El tratamiento de los ficheros con datos de carácter personal, de los
        que HI-SERVICES es responsable, se realiza en:
      </p>
      <p>• Sede principal de HI-SERVICES en Inca - España.</p>
      <p>
        y son tratados por personal técnico de HI-SERVICES predispuesto en
        calidad de encargado de tratamiento, y de eventuales encargados externos
        para las ocasionales operaciones de mantenimiento.
      </p>

      <h3 className="row flex-center text-bg my-5">
        <strong>Consentimiento.</strong>
      </h3>
      <p>
        HI-SERVICES informa que en esta política de privacidad se contienen
        todos los aspectos relacionados con el tratamiento de datos personales
        que HI-SERVICES Ileva a cabo como responsable del mismo. Así, se informa
        que cuando los clientes mantengan o no, relaciones comerciales con
        HI-SERVICES y realicen el envío de un correo electrónico o una
        comunicación a HI-SERVICES, indicando otros datos personales, dicho
        cliente estará dando su consentimiento libre, inequívoco, específico,
        informado y expreso para el tratamiento de sus datos personales por
        HI-SERVICES, con las finalidades establecidas anteriormente, así como
        atender su comunicación o enviar documentación.
      </p>
      <p>
        A los mismos efectos, HI-SERVICES informa de que, si el cliente envía un
        correo electrónico o comunica a HI-SERVICES sus datos personales en
        razón del cargo que ocupa en una empresa -ya sea como administrador,
        gerente, representante y/o cualquier otro cargo como persona de contacto
        en la empresa-, se entenderá que tal comunicación conlleva la prestación
        de su consentimiento libre, inequívoco, específico, informado y expreso
        para el tratamiento de sus datos personales por HI-SERVICES, con las
        finalidades establecidas anteriormente, así como atender su comunicación
        o enviar documentación.
      </p>
      <p>Usuarios Menores De Edad:</p>
      <p>
        HI-SERVICES es consciente que la Página web y los Servicios y productos
        ofrecidos en ella, pueden interesar a un público menor de edad. Por lo
        que compete a la recogida y tratamiento de datos personales, HI-SERVICES
        no permite ninguna operación con los datos personales de menores de
        edad. El registro está, por lo tanto, permitido solamente a los Usuarios
        mayores de edad.
      </p>

      <h3 className="row flex-center text-bg my-5">
        <strong>Registro en la Web.</strong>
      </h3>
      <p>
        Para el disfrute de algunos Servicios reservados (newsletter, listado de
        favoritos, etc), el Usuario debe registrarse entrando en un área
        específica de la Página web.
      </p>
      <p>
        La suscripción a dichos Servicios Especiales, se realiza a través de una
        Iista de favoritos y/o una newsletter, comunicación periódica enviada,
        por medio de correo electrónico, a la dirección indicada por el Usuario,
        a través de la cual, los Usuarios registrados serán informados de las
        iniciativas exclusivas que HI-SERVICES pondrá a disposición de los
        propios Usuarios (“Newsletter”). La Newsletter podrá contener banners de
        publicidad, inserciones y ofertas de publicidad de HI-SERVICES y de
        terceros, igualmente. En el momento del registro, le serán solicitados
        al Usuario sus datos personales, algunos de los cuales —expresamente
        indicados- serán obligatorios (por ejemplo la dirección de correo
        electrónico en la cual recibir las Newsletter). Los otros datos podrán
        ser o no ser introducidos, a discreción del Usuario, pero puede
        imposibilitarle la participación en algunos de nuestros concursos o
        iniciativas; o igualmente permitirle sólo un uso parcial del servicio.
      </p>

      <h3 className="row flex-center text-bg my-5">
        <strong>
          Identificación de los destinatarios respecto de los que HI-SERVICES
          tenga previsto la realización de cesiones o acceso a datos por cuenta
          de terceros.
        </strong>
      </h3>
      <p>
        HI-SERVICES únicamente tiene prevista la realización de comunicaciones
        de datos:
      </p>
      <ul>
        <li>
          En razón de la Ley Orgánica 3/2018 de Protección de Datos de Carácter
          Personal y Garantía de los Derechos Digitales y el Reglamento Europeo
          2016/679 de protección de datos de carácter personal, deba realizar
          para atender sus obligaciones con las Administraciones Públicas en los
          casos que así se requiera de acuerdo con la Legislación vigente en
          cada materia en cada momento y en su caso igualmente, a otros órganos
          como Jueces, Ministerio Fiscal, Tribunales, Ministerio de Finanzas,
          Tribunal de Cuentas, Defensor del Pueblo u otros similares.
        </li>
        <li>
          Entidades Financieras para la domiciliación del cobro de cuotas,
          Empresas Informáticas para soporte y mantenimiento de su sistema
          informático y otros profesionales necesarios para la prestación del
          servicio contratado.
        </li>
      </ul>
      <p>
        Igualmente HI-SERVICES pone en conocimiento de los clientes, que
        cualquier otra cesión de datos que deba realizar, será puesta en su
        conocimiento cuando así lo prevea la LOPDGDD, informándole de modo
        expreso, preciso e inequívoco de los destinatarios de la información, de
        la finalidad a que se destinarán los datos, y de la naturaleza de los
        datos cedidos, o en su caso, cuando la LOPDGDD lo establezca,
        previamente se solicitará el consentimiento inequívoco específico e
        informado al cliente.
      </p>
      <p>
        No obstante, HI-SERVICES informa al cliente que cualquier tratamiento de
        datos personales, se sujeta a la legislación vigente en la Unión Europea
        en materia de protección de datos, establecida por el Reglamento Europeo
        2016/679 de 27 de abril de 2016 y su normativa complementaria y de
        desarrollo. En este sentido, HI-SERVICES sólo es responsable y garantiza
        la confidencialidad de los datos de carácter personal que solicite al
        cliente, no teniendo ningún tipo de responsabilidad respecto de los
        tratamientos y posteriores utilizaciones de los datos personales que
        pudieran efectuarse por terceros prestadores de servicios de la sociedad
        de la información que pudiesen acceder a tales datos en razón de la
        prestación de sus servicios o ejercicio de su actividad.
      </p>
      <p>
        Puede darse el caso de que HI-SERVICES realice la captación y
        manipulación de los datos de carácter personal facilitados por los
        clientes, o destinatarios de campañas, como responsable de tratamiento,
        encargado por un tercero, el cual es el responsable del/os tratamiento/s
        donde se incluirá dicha información. HI-SERVICES informará debidamente
        de dicha situación, indicando el titular del/os tratamiento/s afectados,
        y la forma y dirección donde se podrá ejercer los derechos de Acceso,
        Rectificación, Cancelación, Oposición, Supresión, Limitación del
        tratamiento y Portabilidad de los datos pertinentes.
      </p>
      <p>
        Por terceros prestadores de servicios de la sociedad de la información
        se entenderán -sin carácter Iimitativo- aquellas personas físicas o
        jurídicas que presten los siguientes servicios:
      </p>

      <ul>
        <li>
          Transmisión por una red de comunicación de datos facilitados por los
          destinatarios del servicio.
        </li>
        <li>Servicios de acceso a la citada red.</li>
        <li>Servicios de almacenamiento o alojamiento de datos.</li>
        <li>Suministro de contenidos o información.</li>
      </ul>

      <h3 className="row flex-center text-bg my-5">
        <strong>Calidad de los datos.</strong>
      </h3>
      <p>
        HI-SERVICES advierte al cliente de que, salvo la existencia de una
        representación legalmente constituida, ningún cliente puede utilizar la
        identidad de otra persona y comunicar sus datos personales, por lo que
        los clientes en todo momento deberán tener en cuenta que, solo puede
        incluir datos personales correspondientes a su propia identidad y que
        sean adecuados, pertinentes, actuales, exactos y verdaderos. A tales
        efectos, los clientes serán los únicos responsables frente a cualquier
        daño, directo y/o indirecto que cause a terceros o a HI-SERVICES, por el
        uso de datos personales de otra persona, o sus propios datos personales
        cuando sean falsos, erróneos, no actuales, inadecuados o impertinentes.
        Igualmente los clientes que utilice los datos personales de un tercero,
        responderá ante éste de la obligación de información establecida en el
        Reglamento Europeo 2016/679 de 27 de abril de 2016, para cuando los
        datos de carácter personal no hayan sido recabados del propio
        interesado, y/o de las consecuencias de no haberle informado.
      </p>

      <h3 className="row flex-center text-bg my-5">
        <strong>
          Ejercicio de los derechos de acceso, oposición, rectificación,
          cancelación, supresión, limitación del tratamiento y portabilidad de
          los datos.
        </strong>
      </h3>

      <p>
        HI-SERVICES informa al cliente de la posibilidad de ejercitar de forma
        gratuita, sus derechos de acceso, oposición, rectificación, cancelación,
        supresión, limitación del tratamiento y portabilidad de los datos,
        aportando junto a su solicitud una copia del DNI o documento equivalente
        acreditativo de identidad, dirigiéndose a HI-SERVICES, a través de
        correo electrónico a la dirección: info@hiservices.com, argumentando su
        derecho en función de la Sección 2 del Reglamento Europeo 2016/679 de 27
        de abril de 2016.
      </p>

      <table>
        <tr>
          <th>Derecho</th>
          <th>Contenido</th>
        </tr>
        <tr>
          <td>Acceso</td>
          <td>
            Podrá consultar sus datos personales incluidos en tratamientos de
            HI-SERVICES.
          </td>
        </tr>
        <tr>
          <td>Rectificación</td>
          <td>Podrá modificar sus datos personales cuando sean inexactos.</td>
        </tr>
        <tr>
          <td>Supresión</td>
          <td>Podrá solicitar la eliminación de sus datos personales.</td>
        </tr>
        <tr>
          <td>Oposición</td>
          <td>Podrá solicitar que no se traten sus datos personales.</td>
        </tr>
        <tr>
          <td>Limitación del tratamiento</td>
          <td>
            Podrá solicitar la limitación al tratamiento de sus datos en los
            siguientes casos:
            <ul>
              <li>
                Mientras se comprueba la impugnación de la exactitud de sus
                datos.
              </li>
              <li>
                Cuando el tratamiento es ilícito, pero se oponga a la supresión
                de sus datos.
              </li>
              <li>
                Cuando HI-SERVICES no necesite tratar sus datos pero usted los
                necesite para el ejercicio o la defensa de reclamaciones.
              </li>
              <li>
                Cuando se haya opuesto al tratamiento de sus datos para el
                cumplimiento de una misión en interés público o para la
                satisfacción de un interés legítimo, mientras se verifica si los
                motivos legítimos para el tratamiento prevalecen sobre los
                suyos.
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>Portabilidad</td>
          <td>
            Podrá recibir, en formato electrónico, los datos personales que nos
            haya facilitado y aquellos que se han obtenido de su relación
            contractual con HI-SERVICES, así como a transmitirlos a otra
            entidad.
          </td>
        </tr>
      </table>
      <p>
        En caso de disconformidad con el ejercicio de sus derechos o del
        tratamiento efectuado, puede presentar una reclamación ante la Agencia
        Española de Protección de Datos (www.agpd.es).
      </p>

      <h3 className="row flex-center text-bg my-5">
        <strong>
          Medidas de seguridad adoptadas con relación al tratamiento de los
          datos personales.
        </strong>
      </h3>

      <p>
        HI-SERVICES informa al cliente que, de conformidad con lo dispuesto en
        el Reglamento Europeo 2016/679 de 27 de abril de 2016, ha adoptado las
        medidas de índole técnica y organizativas necesarias para garantizar la
        seguridad de los datos de carácter personal y evitar la alteración,
        pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de
        la tecnología, la naturaleza de los datos almacenados y los riesgos a
        que están expuestos. Igualmente HI-SERVICES garantiza al cliente el
        cumplimiento del deber de secreto profesional respecto de los datos
        personales de los clientes y del deber de guardarlos.
      </p>
    </div>
  );
};
