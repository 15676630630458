import React from 'react';
import headerVideo from "../assets/MainVideo.mp4"
import logo from "../assets/icons/hi-serviceBig.png"
import { Link } from 'react-router-dom';
import Banner from './Banner';
import { Button } from 'reactstrap';
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
import poster from "./../assets/pantalla.png"


const Home = (props) => {


  const elements = [
    "Solmáforo. Mide los niveles de intensidad de radiación ultravioleta (RUV).",
    "alerta a la población basándose en un código de 5 colores (verde, amarillo, naranja, rojo y violeta)",
    "Pantalla digital, táctil e interactiva. Disponible en todos los idiomas.",
    "Test fototipo de piel gratuito y encuestas de valoración de calidad turística.",
    "Protocolos de prevención y actuación adaptados al entorno en el que se integra.",
    "Información y vídeos sobre salud y promoción turística.",
    "Mi playa: nos habla del entorno en el que se ubica el Hi Point.",
    "Desfibrilador externo semiautomático (DESA).",
    "Botón de llamada y asistencia directa con el 112.",
    "Zona de lactancia, descanso y/o sombra.",
    "Zona para carga de dispositivos eléctricos."
  ]

  return (
    <div className="container-fluid">

      <div className="bannerBackground">
        <div className="backBanner p-lg-5 position-relative" style={{ minHeight: "90vh" }}>
          <div className="d-flex flex-row ml-lg-5 p-3">
            <img src={logo} alt="hi-services logo px-lg-5 p-3"/>
          </div>

          <Banner/>

          <div className="d-flex align-items-end flex-column mr-lg-3 position-absolute p-5"
               style={{ bottom: 0, right: 0 }}>
            <div className="d-flex blueColor socialIcons ml-3">
              <a className="d-flex align-items-center mx-3" href="https://www.facebook.com/hihealthinnovation/">
                <FaFacebookF color="white" size="20px"/>
              </a>
              <a className="d-flex align-items-center mx-3" href="https://www.instagram.com/hi_healthinnovation/">
                <FaInstagram color="white" size="23px"/>
              </a>
              <a className="d-flex align-items-center mx-3"
                 href="https://www.youtube.com/channel/UCCbAI0umM4S4vRSkLbYi1ng">
                <FiYoutube color="white" size="26px"/>
              </a>
              <a className="d-flex align-items-center mx-3" href="https://www.linkedin.com/company/hiservices/">
                <FaLinkedin color="white" size="26px"/>
              </a>
            </div>
          </div>
        </div>
      </div>


      {/* elementos que integran un hi point */}
      <div className="container-fluid">
        <div className="row justify-content-center flex-wrap-reverse bg-white">
          <div className="col rowTextHome" style={{ backgroundColor: "#5B7E96"}}>
            <div className="row pt-5 pb-3 px-lg-5 " style={{}}>
              <h1 className="">¿Qué elementos integran un Hi-Point?</h1>
            </div>
            <div className="row px-lg-5">
              <div className="col text-white px-2 m-0">
                {elements.map((text, key) => <div key={key}>
                  {"✓ " + text}
                </div>)}
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="justify-content-center m-auto">
                <div className="d-flex align-middle py-3">
                  <Button><Link to="/contact" className="white">CONTÁCTANOS</Link></Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto mx-0 px-0">
            <div className="row align-items-center h-100 p-lg-3">
              <video controlsList="nodownload" loop id="myVideo" className="headerVideo" src={headerVideo} muted
                     autoPlay controls poster={poster}>
                <source src={headerVideo} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Home;
