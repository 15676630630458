import React, { useState, useEffect } from 'react';
import Config from "../Config"
import moment from "moment";
import { Card, CardBody, CardImg, CardTitle, CardSubtitle, CardFooter } from "reactstrap";
import loading from "../../assets/loading.svg";
import { NavLink } from "react-router-dom";

const Blog = (props) => {
  const [articles, setArticles] = useState(undefined);


  useEffect(() => {
    fetch(`${Config.API_SERVER}/articles`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          const orderArray = data.sort((a, b) => moment(a.date).isBefore(moment(b.date)) ? 1 : -1).filter(({ visible }) => visible)
          setArticles(orderArray);
        }
      })
      .catch(() => {
        setArticles(null);
        console.log("Error al encontrar artículos")
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="container-fluid min-vh-100 pt-5">
      <div className="row">
        <h1 className="px-5 mx-5">Noticias</h1>
      </div>
      {articles === undefined &&
      <div className="row justify-content-center align-items-center" style={{ minHeight: "50vh" }}>
        <img src={loading} width="100px" height="100px" alt=""/>
      </div>}
      <div className="row">
        {articles && articles.map((article, key) => {
          return (
            <NavLink key={key} className="col-12 col-md-6 col-lg-4 my-3" to={"article/" + article.url}
                     style={{ textDecoration: "none" }}>
              <Card className="shadow h-100 bg-white">
                <CardImg top width="100%" src={Config.API_SERVER + (article.image.formats.small || article.image).url}
                         alt="Card image cap"/>
                <CardBody className="text-dark">
                  <CardTitle style={{ fontSize: "20px" }}><strong>{article.name}</strong></CardTitle>
                  <CardSubtitle>{article.author}</CardSubtitle>
                </CardBody>
                <CardFooter className="text-muted w-100">{moment(article.date).format("DD/MM/YYYY HH:mm")}</CardFooter>
              </Card>
            </NavLink>
          )
        })}
      </div>
    </div>
  );
}

export default Blog;
