import config from "./Config"

const AWS = require('aws-sdk');

AWS.config.update({
  region: 'eu-west-1',
  accessKeyId: config.AWS_ACCESS_KEY_ID,
  secretAccessKey: config.AWS_SECRET_ACCESS_KEY,
});

const sendEmail = ({ title, html }) => {


  const params = {
    Destination: {
      CcAddresses: ["info@hi-services.es"],
      ToAddresses: ["info@hi-services.es"]
    },
    Message: { /* required */
      Body: { /* required */
        Html: {
          Charset: "UTF-8",
          Data: html
        },
        Text: {
          Charset: "UTF-8",
          Data: ""
        }
      },
      Subject: {
        Charset: 'UTF-8',
        Data: title
      }
    },
    Source: "hi-service@kinacusports.com", /* required */
  };
  return new AWS.SES({ apiVersion: '2010-12-01' }).sendEmail(params).promise();
};

export { sendEmail };
