import React  from 'react'
import MyMap from "../MyMap";


const MapSection = () => {

  return (
    <div className="container-fluid min-vh-100">
      <MyMap/>
    </div>
  );
}

export default MapSection;
