import React from 'react';
import imgProperty from "./../assets/property.png"


const Property = (props) => {


  return (
    <div className="container-fluid py-5">
      <div className="row justify-content-center">
        <img src={imgProperty} alt=""/>
      </div>
    </div>
  );
};


export default Property;
